import { GetStaticProps } from 'next';

// once kit
// import {  } from '@once/.....';

// custom components
import SplashScreen from './splashScreen';
import ValuePropSection from './valuePropSection';
import ValueProductSection from './valueProductSection';
import PreFooterSection from './preFooterSection';

export const getStaticProps: GetStaticProps = async (ctx) => ({
  props: {
    locale: ctx.locale || null,

    // Adjust your config here! This config is used by the _app.tsx to know what behavior to have on each page.
    // All those config variables may not be used yet, check the code to ensure :)
    config: {
      head: {
        faviconURL: 'https://uploads-ssl.webflow.com/606d6deb975ce72bbba4f515/6074c6a03af1590da7bf96a0_small.png',
        title: 'Once component test page',
        description: 'Once dev environment',
        twitterCard: 'summary_large_image',
        ogImage: 'https://uploads-ssl.webflow.com/606d6deb975ce72bbba4f515/6074c6a03af1590da7bf96a0_small.png',
        ogImageAlt: 'Once',
        ogImageHeight: 32,
        ogImageWidth: 32,
      },
      header: {
        opaqueOnScroll: false,
        disabled: false,
      },
      scroll: {
        snap: false,
        hideScrollbar: true,
        disabled: false,
      },
      cart: {
        disabled: false,
      },
      menu: {
        disabled: false,
      },
      footer: {
        disabled: false,
      },
    },
  },
  revalidate: 3600,
});

export default function Page(): JSX.Element {
  const onComplete = (): void => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      (window as Window).location = 'https://calendly.com/arthurbk/nextstore-expectations';
    } else {
      window.open('https://calendly.com/arthurbk/nextstore-expectations', '_blank', 'popup');
    }
  };

  return (
    <div>
      <SplashScreen onComplete={onComplete} />
      <ValuePropSection onComplete={onComplete} />
      <ValueProductSection onComplete={onComplete} />
      <PreFooterSection onComplete={onComplete} />
    </div>
  );
}
