// once kit
// import {  } from '@once/.....';

// custom components
import { Text } from '@custom/components/common/Text';
import SwipableButton from '@custom/components/common/swipeable-button';
import { Flex } from '@once/components/Layout/Flex';
import { Dropout } from '@custom/components/icons/dropout';

export type PreFooterSectionProps = {
  onComplete: () => void;
};

export default function preFooterSection({ onComplete }: PreFooterSectionProps): JSX.Element {
  return (
    <div>
      <section className="m-10 px-10 pb-10 bg-beige rounded-tl-lg rounded-tr-lg rounded-br-[100px] rounded-bl-[100px]">
        <Flex alignItems="center">
          <Dropout className="fill-white" width="25%" />
        </Flex>
        <div className="pt-28">
          <Flex justifyContent="center" alignItems="center" fillParent>
            <div className="pb-3">
              <Text className="text-purple-rain" size="caption">
                NOW IT&#39;S YOUR TURN
              </Text>
            </div>
            <Flex alignItems="center">
              <Text className="text-dark" size="title">
                Get in touch & take your store
              </Text>
              <Text className="text-dark pb-20" size="title">
                to the next-level
              </Text>
            </Flex>
            <div className="w-[100%] h-32">
              <SwipableButton boldText="" text="Build your nextstore" handleComplete={onComplete} validationText="" />
            </div>
          </Flex>
        </div>
      </section>
    </div>
  );
}
