// custom components
import { Text } from '@custom/components/common/Text';
import SwipableButton from '@custom/components/common/swipeable-button';
import { WordmarkDark } from '@custom/components/icons/wordmark_dark';
import { ArrowDown } from '@custom/components/icons/arrow_down';
import { Card } from '@custom/components/desktop/Card/Card';
import { Image } from '@once/components/Primitive/Image';
import { Flex } from '@once/components/Layout/Flex';

export type SplashScreenProps = {
  onComplete: () => void;
};

export default function SplashScreen({ onComplete }: SplashScreenProps): JSX.Element {
  return (
    <div>
      <section className="px-10 pt-16 z-10" style={{ height: '90vh', position: 'relative' }}>
        <Flex grow={5} fillParent direction="row" gap={25}>
          <div style={{ flex: 10, height: '100%' }}>
            <Flex gap={25} fillParent justifyContent="space-between">
              <div style={{ width: '15%' }}>
                <WordmarkDark className="fill-black" />
              </div>
              <Flex gap={25}>
                <Card classNames="bg-dark pt-24 pr-24">
                  <Text className="text-white" size="title">
                    Modern brands build outstanding shopping experiences.
                  </Text>
                </Card>
                <Flex direction="row" gap={25}>
                  <div style={{ flex: 2, alignSelf: 'flex-end' }}>
                    <ArrowDown />
                  </div>
                  <div style={{ flex: 10 }}>
                    <Card classNames="bg-beige">
                      <Text className="text-komorebi" size="base">
                        Nexstore, the website builder that turns your frontend into a conversion-oriented machine
                      </Text>
                    </Card>
                  </div>
                  <div className="rounded-2xl overflow-hidden border border-cashmere-blue" style={{ flex: 10 }}>
                    <Image
                      image={{
                        id: 'ornement',
                        url: '/ornement.png',
                        altText: 'ornement',
                        width: 980,
                        height: 384,
                        blurDataURL: null,
                      }}
                      wrapper={{ height: '100%', width: '100%' }}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <Flex grow={3} gap={25} justifyContent="space-between">
            <Flex>
              <Image
                image={{
                  id: '900Care',
                  url: '/900Care.png',
                  altText: '900Care',
                  width: 962,
                  height: 1708,
                  blurDataURL: null,
                }}
                wrapper={{ height: '100%', width: '100%' }}
                layout="responsive"
              />
            </Flex>
            <Flex grow={1}>
              <div className="h-10">
                <SwipableButton boldText="" text="Build your nextstore" handleComplete={onComplete} validationText="" />
              </div>
            </Flex>
          </Flex>
        </Flex>
      </section>
      <div className="-mt-10 pb-32">
        <Image
          image={{
            id: 'banner-with-brand',
            url: '/banner-with-brand.png',
            altText: 'banner-with-brand',
            width: 3584,
            height: 804,
            blurDataURL: null,
          }}
          wrapper={{ height: '100%', width: '100%' }}
          layout="responsive"
        />
      </div>
    </div>
  );
}
