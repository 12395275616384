// once kit
// import {  } from '@once/.....';

// custom components
import { Text } from '@custom/components/common/Text';
import SwipableButton from '@custom/components/common/swipeable-button';
import { Card } from '@custom/components/desktop/Card/Card';
import { Image } from '@once/components/Primitive/Image';
import { Flex } from '@once/components/Layout/Flex';
import { SymbolLight } from '@custom/components/icons/symbol_substract_light';

export type ValuePropSectionProps = {
  onComplete: () => void;
};

export default function ValuePropSection({ onComplete }: ValuePropSectionProps): JSX.Element {
  return (
    <div>
      <section>
        <div className="pt-40 pb-80 px-40 bg-dark">
          <div className="flex justify-center gap-2" style={{ height: 19 }}>
            <SymbolLight className="fill-white" height={20} />
            <Text className="text-white" size="base">
              WHAT WE DO AT NEXTSTORE
            </Text>
          </div>
          <div className="mt-6">
            <Text className="text-white text-center" size="title">
              We help you build outstanding experiences for your store. nextstore is a builder that turns your frontend
              into a conversion machine
            </Text>
          </div>
        </div>
        <div className="px-10 h-[35rem] -mt-40">
          <Flex direction="row" gap={16} fillParent>
            <div className="flex-1">
              <Card size="small" classNames="bg-cashmere-blue  h-full relative">
                <div className="flex justify-center gap-2 m-4 " style={{ height: 19 }}>
                  <SymbolLight className="fill-dark" height={20} />
                  <Text className="text-dark" size="base_highlight">
                    WHY NEXTSTORE
                  </Text>
                </div>
                <div className="absolute top-0 left-0" style={{ height: '100%', width: '100%' }}>
                  <Image
                    image={{
                      id: 'benefit--perf--desk',
                      url: '/benefit--perf--desk@2x.png',
                      altText: 'benefit--perf--desk',
                      width: 1066,
                      height: 1420,
                      blurDataURL: null,
                    }}
                    wrapper={{ height: '100%', width: '100%' }}
                    layout="responsive"
                    objectFit="fill"
                  />
                </div>
                <Card size="small" classNames="bg-white absolute bottom-0 m-4 p-4 h-40">
                  <Text className="text-dark" size="base_highlight">
                    Create a unique experience for each device
                  </Text>
                  <Text className="text-komorebi pt-1" size="surtitle">
                    A dedicated frontend for your mobile shop and another for desktop, enabling a mobile-first approach.
                    The traditional responsive approach simply does not make the cut anymore.
                  </Text>
                </Card>
              </Card>
            </div>
            <div className="flex-1">
              <Card size="small" classNames="bg-greige  h-full relative">
                <div className="absolute top-0 left-0" style={{ height: '100%', width: '100%' }}>
                  <Image
                    image={{
                      id: 'benefit--mobile',
                      url: '/benefit--mobile@2x.png',
                      altText: 'benefit--mobile',
                      width: 1066,
                      height: 1418,
                      blurDataURL: null,
                    }}
                    wrapper={{ height: '100%', width: '100%' }}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <Card size="small" classNames="bg-white absolute bottom-0 m-4 p-4 h-40">
                  <Text className="text-dark" size="base_highlight">
                    Unlock great page performances
                  </Text>
                  <Text className="text-komorebi pt-1" size="surtitle">
                    We’ve got your back on the tech side so you don’t have to bother: from instant loading images to
                    pre-built website, everything has been designed to offer superior page performance scoring
                  </Text>
                </Card>
              </Card>
            </div>
            <div className="flex-1">
              <Card size="small" classNames="bg-beige  h-full relative">
                <div className="absolute top-0 left-0" style={{ height: '100%', width: '100%' }}>
                  <Image
                    image={{
                      id: 'benefit--empowering--desk',
                      url: '/benefit--empowering--desk@2x.png',
                      altText: 'benefit--empowering--desk',
                      width: 1066,
                      height: 1420,
                      blurDataURL: null,
                    }}
                    wrapper={{ height: '100%', width: '100%' }}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <Card size="small" classNames="bg-white absolute bottom-0 m-4 p-4 h-40">
                  <Text className="text-dark" size="base_highlight">
                    Iterate in a few days, not weeks
                  </Text>
                  <Text className="text-komorebi pt-1" size="surtitle">
                    Our drag and drop builder along with our ready-to-use library of component allows you to AB test new
                    experiences in a few days, no more back and forth during weeks with your agency: the best recipe to
                    optimise your conversion rates.
                  </Text>
                </Card>
              </Card>
            </div>
          </Flex>
        </div>
        <div>
          <div className="relative py-40">
            <div>
              <Image
                image={{
                  id: 'next_banner',
                  url: '/next_banner.png',
                  altText: 'next_banner',
                  width: 3584,
                  height: 804,
                  blurDataURL: null,
                }}
                wrapper={{ height: '100%', width: '100%' }}
                layout="responsive"
              />
            </div>
            <div className="absolute top-0 left-0 z-10 h-[100%] w-[100%]">
              <Flex justifyContent="center" alignItems="center" fillParent>
                <Text className="text-purple-rain" size="caption">
                  NOW IT&#39;S YOUR TURN
                </Text>
                <Text className="text-dark pb-20" size="title">
                  Take your store to the next level
                </Text>
                <div className="w-80 h-12">
                  <SwipableButton
                    boldText=""
                    text="Build your nextstore"
                    handleComplete={onComplete}
                    validationText=""
                  />
                </div>
              </Flex>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
