export function ArrowDown({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} viewBox="0 0 97 97" fill="none">
      <rect x="0.5" y="0.5" width="96" height="96" rx="48" fill="#FFFEFC" />
      <path d="M48.5 57.649L48.5 37.649" stroke="#212121" strokeMiterlimit="10" />
      <path
        d="M58.125 48.3157L48.5 57.649L38.875 48.3157"
        stroke="#212121"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <rect x="0.5" y="0.5" width="96" height="96" rx="48" stroke="#CFD0C5" />
    </svg>
  );
}
